import Vue from "vue";
//SignalR
import signalRConnection from "./signalR";

Vue.prototype.$signalR = signalRConnection; // SignalR bağlantısını global olarak tanımla

import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";

import axios from "axios";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

//devexpress
import "devextreme/dist/css/dx.light.css";

import moment from "moment";

// main.js veya ilgili bileşen dosyasına aşağıdaki importları ekleyin.
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Tarih formatlayıcı fonksiyonunu tanımla
Vue.filter("formatDate", function (value) {
  if (!value) return "";
  const date = new Date(value);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`; // Sadece Gün.Ay.Yıl formatında döner
});

Vue.filter("numberFormat", function (value) {
  if (!value) return "0";
  return value.toLocaleString("en-US"); // Sayıyı 1,000.00 gibi formatlar
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = true;
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY - HH:mm");
  }
});
Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(String(value)).format("HH:mm");
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
