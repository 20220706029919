import * as signalR from "@microsoft/signalr";

// SignalR bağlantısı oluşturma
const clientId =
  window.location.hostname === "localhost" ? "localWebRomatem" : "WebRomatem";
const hubUrl = "https://rpapi.romatem.net/heartbeatHub";

// SignalR bağlantısı
let connection;

const createConnection = () => {
  const token = localStorage.getItem("RPaccessToken");
  if (!token) {
    console.warn("Token bulunamadı. SignalR bağlantısı başlatılmadı.");
    return null; // Token yoksa bağlantı başlatılmaz
  }

  return new signalR.HubConnectionBuilder()
    .withUrl(`${hubUrl}?ClientId=${clientId}`, {
      accessTokenFactory: () => token, // Token'i buradan gönderiyoruz
    })
    .withAutomaticReconnect()
    .build();
};

// Bağlantının hazır olduğunu takip etmek için bir Promise
let isReadyResolve;
const isReady = new Promise((resolve) => {
  isReadyResolve = resolve; // Bağlantı hazır olduğunda bu tetiklenecek
});

// Bağlantıyı başlatma
const startConnection = async () => {
  try {
    if (!connection) {
      console.warn("Bağlantı başlatılmadı çünkü token mevcut değil.");
      return; // Token yoksa bağlantı denemesi yapılmaz
    }

    if (connection.state === signalR.HubConnectionState.Disconnected) {
      await connection.start();
      console.log("SignalR bağlantısı başarılı.");
      isReadyResolve(); // Bağlantı başarıyla kurulduğunda ready'yi tetikle
    }
  } catch (error) {
    console.error("SignalR bağlantısı başarısız:", error);
    setTimeout(startConnection, 5000); // Yeniden bağlanma denemesi
  }
};

// Token var mı kontrol et ve bağlantıyı oluştur
const initializeConnection = () => {
  if (!localStorage.getItem("RPaccessToken")) {
    console.warn("Token mevcut değil, SignalR bağlantısı kurulmayacak.");
    return;
  }

  connection = createConnection();
  startConnection();
};

// Mesaj gönderme fonksiyonu (bağlantının hazır olmasını bekler)
const invokeSafe = async (methodName, ...args) => {
  await isReady; // Bağlantının hazır olmasını bekle
  return connection.invoke(methodName, ...args);
};

// Dinleyiciler eklenebilir
const setupListeners = () => {
  if (connection) {
    connection.on("ReceiveMessage", (message) => {
      console.log("Sunucudan mesaj alındı:", message);
    });
  }
};

// Bağlantıyı başlat
initializeConnection();

export default {
  connection,
  invokeSafe,
  isReady,
  initializeConnection, // Dışarıdan bağlantı tekrar başlatılabilir
  setupListeners, // Dinleyiciler kurulabilir
};
